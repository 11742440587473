import { i18n } from "@/i18n";

const { t } = i18n.global;

interface Product {
  slug: string;
  type: "regular" | "special";
  color: string;
  isNew: boolean;
  text: {
    name: string;
    brand: string;
    description: string;
    age: string;
    body: string;
    ingredients: string;
    preparation: string;
  };
  image: {
    packshot: string;
    packshotThumb: string;
    table: string;
  };
  medals?: string[];
}

const getPreparation = (
  brand: string,
  tableUrl?: string,
  gramsPerSpoon?: string,
  tableHtml?: string,
  preparationTemperature?: string,
) => {
  let html;
  if (gramsPerSpoon && tableUrl) {
    html = t("product.preparation.table", [tableUrl, gramsPerSpoon]);
  } else {
    html = tableHtml;
  }

  return `
    <div class="title">
      ${t("product.preparation.tableTitle")}
    </div>
    <div class="table-holder">
      ${html}
    </div> 
    <div class="title">
      ${t("product.preparation.instructions.title")}
    </div>
    <div class="emphasis">
      ${t("product.preparation.instructions.subtitle")}
    </div>
    <img class="prep-img mx-auto" src="/img/products/preparation-a.svg" />
    <div class="text">
      <strong>1.</strong> ${t("product.preparation.instructions.number1")}<br/>
      <strong>2.</strong> ${t("product.preparation.instructions.number2", [preparationTemperature])}
    </div>
    <img class="prep-img mx-auto" src="/img/products/preparation-b.svg" />
    <div class="text">
      <strong>3.</strong> ${t("product.preparation.instructions.number3")}<br/>
      <strong>4.</strong> ${t("product.preparation.instructions.number4")}
    </div>
    <img class="prep-img mx-auto" src="/img/products/preparation-c.svg" />
    <div class="text">
      <strong>5.</strong> ${t("product.preparation.instructions.number5")}<br/>
      <strong>6.</strong> ${t("product.preparation.instructions.number6")}
    </div>
    <img class="prep-img mx-auto" src="/img/products/preparation-d.svg" />
    <div class="text">
      <strong>7.</strong> ${t("product.preparation.instructions.number7", [brand])}<br/>
      <strong>8.</strong> ${t("product.preparation.instructions.number8")}
    </div>
    <div class="warning">
      ${t("product.preparation.instructions.warning", [brand])}
    </div>`;
};

const medalsPaths = {
  dhaAa: "/img/products/medals/dha-aa.png",
  easyDigestion: "/img/products/medals/easy-digestion.png",
  gos: "/img/products/medals/gos.png",
  hidroProtein: "/img/products/medals/hidro-protein.png",
  noPalmOil: "/img/products/medals/no-palm-oil.png",
  carobGumGreen: "/img/products/medals/carob-gum--green.png",
  dhaAaGreen: "/img/products/medals/dha-aa--green.png",
  gosGreen: "/img/products/medals/gos--green.png",
  partiallyHydrolyzedProteinGreen:
    "/img/products/medals/partially-hydrolyzed-protein--green.png"
};

function getProducts(): Product[] {
  return [
    {
      slug: "frisolac-gold-1",
      type: "regular",
      color: "#00468f",
      isNew: false,
      text: {
        name: t("product.frisolacGold1.name"),
        brand: t("product.frisolacGold1.brand"),
        description: t("product.frisolacGold1.description"),
        age: t("product.frisolacGold1.age"),
        body: t("product.frisolacGold1.body"),
        ingredients: t("product.frisolacGold1.ingredients"),
        preparation: getPreparation(
          t("product.frisolacGold1.brand"),
          t("product.frisolacGold1.preparationTableUrl"),
          `4,3`,
          undefined,
          "40"
        )
      },
      image: {
        packshot: "/img/products/packshots/friso-gold-1.png",
        packshotThumb: "/img/products/packshots/friso-gold-1--thumb.png",
        table: "/img/products/tables/friso-gold-1.svg"
      },
      medals: [
        medalsPaths.easyDigestion,
        medalsPaths.noPalmOil,
        medalsPaths.gos,
        medalsPaths.dhaAa
      ]
    },
    {
      slug: "frisolac-gold-2",
      type: "regular",
      color: "#a2c035",
      isNew: false,
      text: {
        name: t("product.frisolacGold2.name"),
        brand: t("product.frisolacGold2.brand"),
        description: t("product.frisolacGold2.description"),
        age: t("product.frisolacGold2.age"),
        body: t("product.frisolacGold2.body"),
        ingredients: t("product.frisolacGold2.ingredients"),
        preparation: getPreparation(
          t("product.frisolacGold2.brand"),
          t("product.frisolacGold2.preparationTableUrl"),
          `4,3`,
          undefined,
          "40"
        )
      },
      image: {
        packshot: "/img/products/packshots/friso-gold-2.png",
        packshotThumb: "/img/products/packshots/friso-gold-2--thumb.png",
        table: "/img/products/tables/friso-gold-2.svg"
      },
      medals: [
        medalsPaths.easyDigestion,
        medalsPaths.noPalmOil,
        medalsPaths.gos,
        medalsPaths.dhaAa
      ]
    },
    {
      slug: "friso-gold-3",
      type: "regular",
      color: "#d76212",
      isNew: false,
      text: {
        name: t("product.frisolacGold3.name"),
        brand: t("product.frisolacGold3.brand"),
        description: t("product.frisolacGold3.description"),
        age: t("product.frisolacGold3.age"),
        body: t("product.frisolacGold3.body"),
        ingredients: t("product.frisolacGold3.ingredients"),
        preparation: getPreparation(
          t("product.frisolacGold3.brand"),
          undefined,
          undefined,
          t("product.frisolacGold3.preparationTableExtra"),
          "40"
        )
      },
      image: {
        packshot: "/img/products/packshots/friso-gold-3.png",
        packshotThumb: "/img/products/packshots/friso-gold-3--thumb.png",
        table: "/img/products/tables/friso-gold-3.svg"
      },
      medals: [
        medalsPaths.easyDigestion,
        medalsPaths.noPalmOil,
        medalsPaths.gos,
        medalsPaths.dhaAa
      ]
    },
    {
      slug: "frisolac-vom-comfort-1",
      type: "special",
      color: "#579a50",
      isNew: false,
      text: {
        name: t("product.frisoVom1.name"),
        brand: t("product.frisoVom1.brand"),
        description: t("product.frisoVom1.description"),
        age: t("product.frisoVom1.age"),
        body: t("product.frisoVom1.body"),
        ingredients: t("product.frisoVom1.ingredients"),
        preparation: getPreparation(
          t("product.frisoVom1.brand"),
          undefined,
          undefined,
          t("product.frisoVom1.preparationTableExtra"),
          "75"
        )
      },
      image: {
        packshot: "/img/products/packshots/friso-vom-1.png",
        packshotThumb: "/img/products/packshots/friso-vom-1--thumb.png",
        table: "/img/products/tables/friso-vom-1.svg"
      },
      medals: [medalsPaths.gos, medalsPaths.dhaAa]
    },
    {
      slug: "frisolac-vom-comfort-2",
      type: "special",
      color: "#1e632b",
      isNew: false,
      text: {
        name: t("product.frisoVom2.name"),
        brand: t("product.frisoVom2.brand"),
        description: t("product.frisoVom2.description"),
        age: t("product.frisoVom2.age"),
        body: t("product.frisoVom2.body"),
        ingredients: t("product.frisoVom2.ingredients"),
        preparation: getPreparation(
          t("product.frisoVom2.brand"),
          undefined,
          undefined,
          t("product.frisoVom2.preparationTableExtra"),
          "75"
        )
      },
      image: {
        packshot: "/img/products/packshots/friso-vom-2.png",
        packshotThumb: "/img/products/packshots/friso-vom-2--thumb.png",
        table: "/img/products/tables/friso-vom-2.svg"
      },
      medals: [medalsPaths.gos, medalsPaths.dhaAa]
    },
    {
      slug: "frisolac-gold-premature",
      type: "special",
      color: "#ba296a",
      isNew: false,
      text: {
        name: t("product.frisolacGoldPremature.name"),
        brand: t("product.frisolacGoldPremature.brand"),
        description: t("product.frisolacGoldPremature.description"),
        age: t("product.frisolacGoldPremature.age"),
        body: t("product.frisolacGoldPremature.body"),
        ingredients: t("product.frisolacGoldPremature.ingredients"),
        preparation: getPreparation(
          t("product.frisolacGoldPremature.brand"),
          undefined,
          undefined,
          t("product.frisolacGoldPremature.preparationTableExtra"),
          "40"
        )
      },
      image: {
        packshot: "/img/products/packshots/frisolac-gold-premature.png",
        packshotThumb:
          "/img/products/packshots/frisolac-gold-premature--thumb.png",
        table: "/img/products/tables/frisolac-gold-premature.svg"
      },
      medals: [medalsPaths.gos, medalsPaths.dhaAa, medalsPaths.hidroProtein]
    },
    {
      slug: "friso-pep",
      type: "special",
      color: "#beb4d4",
      isNew: false,
      text: {
        name: t("product.frisoPep.name"),
        brand: t("product.frisoPep.brand"),
        description: t("product.frisoPep.description"),
        age: t("product.frisoPep.age"),
        body: t("product.frisoPep.body"),
        ingredients: t("product.frisoPep.ingredients"),
        preparation: getPreparation(
          t("product.frisoPep.brand"),
          undefined,
          undefined,
          t("product.frisoPep.preparationTableExtra"),
          "40"
        )
      },
      image: {
        packshot: "/img/products/packshots/friso-pep.png",
        packshotThumb: "/img/products/packshots/friso-pep--thumb.png",
        table: "/img/products/tables/friso-pep.svg"
      },
      medals: [medalsPaths.hidroProtein, medalsPaths.gos, medalsPaths.dhaAa]
    },
    {
      slug: "friso-pep-ac",
      type: "special",
      color: "#a26ea4",
      isNew: false,
      text: {
        name: t("product.frisoPepAc.name"),
        brand: t("product.frisoPepAc.brand"),
        description: t("product.frisoPepAc.description"),
        age: t("product.frisoPepAc.age"),
        body: t("product.frisoPepAc.body"),
        ingredients: t("product.frisoPepAc.ingredients"),
        preparation: getPreparation(
          t("product.frisoPepAc.brand"),
          undefined,
          undefined,
          t("product.frisoPepAc.preparationTableExtra"),
          "40"
        )
      },
      image: {
        packshot: "/img/products/packshots/friso-pep-ac.png",
        packshotThumb: "/img/products/packshots/friso-pep-ac--thumb.png",
        table: "/img/products/tables/friso-pep-ac.svg"
      },
      medals: [medalsPaths.hidroProtein]
    },
    {
      slug: "frisolac-gold-comfort-ar",
      type: "special",
      color: "#8dcfd4",
      isNew: false,
      text: {
        name: t("product.frisolacGoldComfortAr.name"),
        brand: t("product.frisolacGoldComfortAr.brand"),
        description: t("product.frisolacGoldComfortAr.description"),
        age: t("product.frisolacGoldComfortAr.age"),
        body: t("product.frisolacGoldComfortAr.body"),
        ingredients: t("product.frisolacGoldComfortAr.ingredients"),
        preparation: getPreparation(
          t("product.frisolacGoldComfortAr.brand"),
          t("product.frisolacGoldComfortAr.preparationTableUrl"),
          `N,N`,
          undefined,
          "40"
        )
      },
      image: {
        packshot: "/img/products/packshots/frisolac-gold-comfort-ar.png",
        packshotThumb:
          "/img/products/packshots/frisolac-gold-comfort-ar--thumb.png",
        table: "/img/products/tables/frisolac-gold-comfort-ar.svg"
      },
      medals: [
        medalsPaths.dhaAaGreen,
        medalsPaths.carobGumGreen,
        medalsPaths.gosGreen,
        medalsPaths.partiallyHydrolyzedProteinGreen
      ]
    }
  ];
}

function getProduct(slug: string) {
  const products = getProducts();
  return products.find(prod => prod.slug === slug);
}

export { getProducts, getProduct };
